import { IBlobListItem } from './BlobList';

/**
 * @param {IBlobListItem} props - The props for the Blob component
 * @returns {React.FC<IBlobListItem>} Blob Component
 */
const BlobThree: React.FC<IBlobListItem> = ({
  size,
  color,
  id = 'blob-three',
}) => {
  return (
    <svg
      height={size}
      id={id}
      data-testid="svg-blob-three"
      viewBox="0 0 316 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M209.373 85.341C209.373 116.332 184.218 113.958 153.187 113.958C122.156 113.958 97 116.332 97 85.341C113.856 -17.1317 152.496 2.50801 183.527 2.50801C245.895 8.68072 209.373 54.3502 209.373 85.341Z"
        fill={color}
      />
    </svg>
  );
};

export default BlobThree;
