import Blob, { BlobType } from '@/components/atoms/Blob/Blob';
import Icon, { IconType } from '@/components/atoms/Icon/Icon';
import React from 'react';

/**
 * IIconCard
 *
 * @interface
 */
export interface IIconCard {
  /** The title to display. */
  title: string;
  /** The description to display. */
  description: string | React.ReactNode;
  /** The blob to display */
  blob: BlobType;
  /** The icon to display */
  icon: IconType;
  /**
   * The optional classes to apply to the component.
   *
   * @default ''
   */
  className?: string;
}

/**
 * IIconCard Displays an image, title and description. Description is passed in
 * as either a string or a React Node so things like text with links can be
 * passed in
 *
 * @param {IIconCard} props - The props for the IconCard component
 * @returns {React.FC<IIconCard>} IIconCard Component
 */
const IconCard: React.FC<IIconCard> = ({
  title,
  description,
  blob,
  icon,
  className = '',
}) => {
  return (
    <div
      data-testid="icon-card"
      className={`w-full px-6 mb:px-8 text-center ${className}`}
    >
      <div className="relative h-[114px] mb-6 md:mb-8 flex items-center justify-center">
        <Blob blob={blob} size={114} colorType="base" colorShade={100} />
        <Icon
          icon={icon}
          size={80}
          colorType="base"
          colorShade={400}
          classes="absolute"
        />
      </div>
      <span className="text-h5 block mb-4 text-mkNavy-400 font-bold font-petco">
        {title}
      </span>
      <div className="[&>p>a]:underline [&>p>a]:hover:text-base-300">
        {description}
      </div>
    </div>
  );
};

export default IconCard;
