import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';

/**
 * IDisplayText Interface for the Display Text Component
 *
 * @interface
 */
export interface IDisplayText {
  /** The overline text to display. */
  overline?: string;
  /** The heading text to display. */
  heading?: React.ReactNode | string;
  /**
   * The optional classes to apply to the component.
   *
   * @default ''
   */
  classes?: string;
}

/**
 * Display Text Display Text - takes in overline, heading and classes
 *
 * @param {IDisplayText} props - The props for the Display Text Component
 * @returns {React.FC<IDisplayText>} Display Text Component
 */
const DisplayText: React.FC<IDisplayText> = ({
  overline,
  heading,
  classes,
}) => {
  return (
    <div data-testid="display-text" className={`text-center w-full ${classes}`}>
      <AnimateItem from={{ translateY: 20 }}>
        {overline && (
          <Paragraph
            size="body3"
            font="petco"
            className="text-neutral-800 mb-4"
          >
            {overline}
          </Paragraph>
        )}
        {heading && (
          <Heading size="h2" font="amasis" className="text-neutral-800">
            {heading}
          </Heading>
        )}
      </AnimateItem>
    </div>
  );
};

DisplayText.defaultProps = {
  classes: '',
};

export default DisplayText;
