import { IBlobListItem } from './BlobList';

/**
 * @param {IBlobListItem} props - The props for the Blob component
 * @returns {React.FC<IBlobListItem>} Blob Component
 */
const BlobOne: React.FC<IBlobListItem> = ({ size, color, id = 'blob-one' }) => {
  return (
    <svg
      height={size}
      id={id}
      data-testid="svg-blob-one"
      viewBox="0 0 316 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M246 46C246 77.2041 206.601 102.5 158 102.5C109.399 102.5 70 77.2041 70 46C70 14.7959 109.399 12 158 12C206.601 12 246 14.7959 246 46Z"
        fill={color}
      />
    </svg>
  );
};

export default BlobOne;
