import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import ButtonLink from '@/components/atoms/ButtonLink/ButtonLink';
import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * IImageContent1by1
 *
 * @interface
 */
export interface IImageContent1by1 {
  /** The text to display */
  overline: string;
  /** The title to display */
  headline: React.ReactNode;
  /** The image src */
  imageSrc: string;
  /** The image alt */
  imageAlt?: string;
  /** The copy to display */
  copy: string | React.ReactNode;
  /** The orientation of the image */
  orientation?: 'Left' | 'Right';
  /** Hide the background */
  hideBackground?: boolean;
  /** The button */
  button?: {
    /** The label to display on the button. */
    label: string;
    /** The URL that the button should navigate to. */
    url: string;
    /** The target window or frame that the linked document will open in. */
    target?: '_blank' | '_self' | '_parent' | '_top';
    /** The alternate text to display when the image cannot be displayed. */
    altTitle?: string;
    /** The onClick event handler. */
    onClick?: (event: React.MouseEvent) => void;
  };
  /** Optional className */
  className?: string;
}

/**
 * ImageContent1by1 Image Content1by1 Organism
 *
 * @param {IImageContent1by1} props - The props for the ImageContent1by1
 *   component
 * @returns {React.FC<IImageContent1by1>} Component
 */
const ImageContent1by1: React.FC<IImageContent1by1> = ({
  overline,
  headline,
  imageSrc,
  imageAlt,
  copy,
  button,
  orientation = 'Left',
  hideBackground = false,
  className,
}) => {
  return (
    <div
      data-testid="image-content1by1"
      className={twMerge(
        clsx(
          'relative mb-16 sm:mb-20 md:mb-28',
          className,
          hideBackground ? '' : 'lg:pt-20'
        )
      )}
    >
      {!hideBackground ? (
        <div className="hidden md:flex max-w-[1440px] mx-auto">
          {orientation === 'Left' ? (
            <>
              <div className="-mt-10 lg:-mt-20 bg-base-200 w-[41%] h-[472px] md:h-[840px] lg:h-[1000px] after:hidden before:xl:block before:bg-base-200 before:h-[472px] before:md:h-[840px] before:lg:h-[1000px] before:absolute before:left-0 before:w-1/3" />
              <div className="bg-base-100 w-[59%] h-[530px] md:h-[840px] lg:h-[1000px] before:hidden after:xl:block after:bg-base-100 after:h-[530px] after:lg:h-[1000px] after:absolute after:right-0 after:w-1/3" />
            </>
          ) : (
            <>
              <div className="bg-base-100 w-[59%] h-[530px] md:h-[840px] lg:h-[1000px] after:hidden before:xl:block before:bg-base-100 before:h-[472px] before:md:h-[840px] before:lg:h-[1000px] before:absolute before:left-0 before:w-1/3" />
              <div className="-mt-10 lg:-mt-20 bg-base-200 w-[41%] h-[472px] md:h-[840px] lg:h-[1000px] before:hidden after:xl:block after:bg-base-200 after:h-[530px] after:lg:h-[1000px] after:absolute after:right-0 after:w-1/3" />
            </>
          )}
        </div>
      ) : null}

      <div
        className={clsx(
          'md:bg-unset inset-0 z-[1] top-12 md:top-[72px] before:block before:md:hidden before:absolute before:top-0 before:w-full before:h-40 md:pb-0 before:sm:h-80',
          hideBackground
            ? 'bg-none before:bg-none'
            : 'bg-base-100 before:bg-base-200 py-12 sm:py-16 md:absolute md:pt-20'
        )}
      >
        <div className="page-max-w">
          <div className="md:grid md:grid-cols-12 md:gap-6 md:items-center">
            <div
              className={clsx(
                'md:col-span-7',
                orientation === 'Left'
                  ? 'md:order-1'
                  : 'md:order-2 md:col-start-6'
              )}
            >
              <div className="w-full mb-6 md:mb-0">
                <ContentfulImage
                  src={imageSrc}
                  alt={imageAlt}
                  layout="responsive"
                  width={780}
                  height={780}
                  objectFit="cover"
                  className="rounded-3xl overflow-hidden"
                />
              </div>
            </div>
            <div
              className={clsx(
                'md:col-span-4',
                orientation === 'Left'
                  ? 'md:order-2 md:col-start-9'
                  : 'md:order-1'
              )}
            >
              <AnimateItem from={{ translateY: 20 }}>
                <Paragraph
                  size="body3"
                  font="petco"
                  className="text-neutral-700 mb-2 lg:mb-3"
                >
                  {overline}
                </Paragraph>
                <Heading
                  size="h2"
                  className="text-mkNavy-400 [&>*]:text-mkNavy-400 [&>p>*]:text-mkNavy-400 mb-4 lg:mb-8"
                >
                  {headline}
                </Heading>
                <div className="rich-text-with-links font-petco">{copy}</div>
                {button && (
                  <ButtonLink
                    variant={'light'}
                    color="primary"
                    href={button.url}
                    alt={button.altTitle}
                    target={button.target}
                    className="border-b-0"
                    onClick={button?.onClick}
                  >
                    {button.label}
                  </ButtonLink>
                )}
              </AnimateItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageContent1by1;
