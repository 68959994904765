import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import Heading from '@/components/atoms/Heading/Heading';
import PageWrapper from '@/components/organisms/PageWrapper/PageWrapper';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import clsx from 'clsx';
import { ContainerProps } from '..';
import { assemblyFactory } from '../../assemblyFactory';
import { ActionLinkContainer } from '../ActionLinkContainer/ActionLinkContainer';

/**
 * @param {object} props - BlockContainer props
 * @returns {React.ReactNode} - BlockContainer component
 */
export const BlockContainer = (props: ContainerProps['block']) => {
  const columnsTablet =
    props.fields.columnsTablet === 'Auto'
      ? props.fields.elements?.length
      : props.fields.columnsTablet;

  const columnsDesktop =
    props.fields.columnsDesktop === 'Auto'
      ? props.fields.elements?.length
      : props.fields.columnsDesktop;

  const backgroundColor = props.fields.background;

  return (
    <div
      style={{
        backgroundColor,
      }}
      className="block-container overflow-hidden"
    >
      <PageWrapper
        data-testid="block-container"
        classes={clsx(
          'page-max-w',
          props.fields.keepPadding && 'py-16 sm:py-20 md:py-28'
        )}
      >
        {props.fields.headline && (
          <div
            className={clsx(
              'flex flex-col md:flex-row mb-6 md:mb-16 items-start md:items-end',
              props.fields.actionLinkOrientation === 'Left'
                ? 'flex-row-reverse'
                : 'flex-row'
            )}
          >
            <div
              className={clsx(
                'flex-grow',
                !!props.fields.actionLink &&
                  props.fields.actionLinkOrientation === 'Left'
                  ? 'text-right'
                  : !props.fields.actionLink
                  ? 'text-center w-full'
                  : 'text-left'
              )}
            >
              {props.fields.headline && (
                <AnimateItem from={{ translateY: 20 }}>
                  <Heading
                    size="h2"
                    font="petco"
                    className="text-mkNavy-400 [&>*]:text-mkNavy-400 mb-6"
                  >
                    {documentToReactComponents(props.fields.headline)}
                  </Heading>
                </AnimateItem>
              )}
              {props.fields.copy && (
                <AnimateItem
                  from={{ translateY: 20 }}
                  delay={0.4}
                  className="[&>*]:text-lg max-w-5xl mb-6 md:mb-0"
                >
                  {documentToReactComponents(props.fields.copy)}
                </AnimateItem>
              )}
            </div>
            {props.fields.actionLink && (
              <div>
                <ActionLinkContainer {...props.fields.actionLink} />
              </div>
            )}
          </div>
        )}
        <div
          data-testid="block-elements-container"
          className={`grid sm:grid-cols-${columnsTablet} md:grid-cols-${columnsDesktop} gap-10 sm:gap-12 md:gap-8`}
        >
          {assemblyFactory.createBlockElements(props.fields.elements)}
        </div>
      </PageWrapper>
    </div>
  );
};
