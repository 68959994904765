import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('@/components/organisms/Footer/Footer'));
const Navbar = dynamic(() => import('@/components/organisms/Navbar/Navbar'));
const ToastHandler = dynamic(
  () => import('@/components/organisms/ToastHandler/ToastHandler')
);

import { mockFooterProps } from '@/components/organisms/Footer/Footer.mocks';
import { useUserContext } from '@/lib/hooks/userContext/UserContext';

/**
 * IPrimaryLayout
 *
 * @interface
 */
export interface IPrimaryLayout extends React.ComponentPropsWithoutRef<'div'> {
  /** The children of the component */
  children: React.ReactNode;
}

/**
 * Primary Layout Used to display the primary layout of the application.
 *
 * @param {IPrimaryLayout} props - The props for the PrimaryLayout component.
 * @returns {React.FC<IPrimaryLayout>} PrimaryLayout Component
 */
const PrimaryLayout: React.FC<IPrimaryLayout> = ({
  children,
}: IPrimaryLayout) => {
  const { hasMessage, isFetchingUser, isLoggedIn, isShelter } =
    useUserContext();

  return (
    <>
      <Navbar
        isFetchingUser={isFetchingUser}
        loggedIn={isLoggedIn}
        isShelter={isShelter}
        hasMessage={hasMessage}
      />
      <main data-testid="primaryLayout">
        {children}
        <ToastHandler />
      </main>
      <Footer {...mockFooterProps.base} />
    </>
  );
};

export default PrimaryLayout;
