import Head from 'next/head';

/**
 * IBlankLayout
 *
 * @interface
 */
export interface IBlankLayout {
  /** The children of the Blank Layout */
  children: React.ReactNode;
}

/**
 * Blank Layout Used to display the layout of the application.
 *
 * @param {IBlankLayout} props - The props for the Blank Layout component.
 * @returns {React.FC<IBlankLayout>} BlankLayout Component
 */
const BlankLayout: React.FC<IBlankLayout> = ({ children }: IBlankLayout) => {
  return (
    <>
      <Head>
        <title>Petco Love Lost</title>
      </Head>
      <main data-testid="blankLayout">{children}</main>
    </>
  );
};

export default BlankLayout;
