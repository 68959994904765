import Navbar from '@/components/organisms/Navbar/Navbar';
import ToastHandler from '@/components/organisms/ToastHandler/ToastHandler';
import { useUserContext } from '@/lib/hooks/userContext/UserContext';

/**
 * INoFooterLayout
 *
 * @interface
 */
export interface INoFooterLayout {
  /** The children of the component */
  children: React.ReactNode;
}

/**
 * NoFooter Layout Used to display the layout of the application without a
 * footer.
 *
 * @param {INoFooterLayout} props - The props for the NoFooterLayout component.
 * @returns {React.FC<INoFooterLayout>} NoFooterLayout Component
 */
const NoFooterLayout: React.FC<INoFooterLayout> = ({
  children,
}: INoFooterLayout) => {
  const { hasMessage, isFetchingUser, isLoggedIn, isShelter } =
    useUserContext();
  return (
    <>
      <Navbar
        isFetchingUser={isFetchingUser}
        loggedIn={isLoggedIn}
        isShelter={isShelter}
        hasMessage={hasMessage}
      />
      <main data-testid="noFooterLayout">
        {children}
        <ToastHandler />
      </main>
    </>
  );
};

export default NoFooterLayout;
