import BlobFour from './BlobFour';
import BlobOne from './BlobOne';
import BlobThree from './BlobThree';
import BlobTwo from './BlobTwo';

/** The type of the Blob */
export interface IBlobListItem {
  /** The size of the blob */
  size: number;
  /** The color of the blob */
  color: string;
  /** The id of the blob */
  id?: string;
}

const BlobList = {
  blobOne: BlobOne,
  blobTwo: BlobTwo,
  blobThree: BlobThree,
  blobFour: BlobFour,
};

export default BlobList;
