import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import useWindowSize from '@/lib/hooks/windowSize/WindowSize';
import contentFulImageLoader from '@/lib/loaders/contentfulImageLoader';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import PageWrapper from '../PageWrapper/PageWrapper';

/**
 * Interface for a logo item component
 *
 * @interface ILogoItem
 */
interface ILogoItem {
  /** The logo image url */
  url: string;
  /** The logo image alt text */
  alt?: string;
}

/**
 * ILogoGrid
 *
 * @interface
 */
export interface ILogoGrid {
  /** The heading text */
  heading: Document;
  /** The logos list */
  logos: ILogoItem[];
}

const options: Options = {
  renderNode: {
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.HEADING_1]: (_, children) => (
      <Heading size="h1" className="text-center">
        {children}
      </Heading>
    ),
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.HEADING_2]: (_, children) => (
      <Heading size="h2" className="text-center">
        {children}
      </Heading>
    ),
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.HEADING_3]: (_, children) => (
      <Heading size="h3" className="text-center">
        {children}
      </Heading>
    ),
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.HEADING_4]: (_, children) => (
      <Heading size="h4" className="text-center">
        {children}
      </Heading>
    ),
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.HEADING_5]: (_, children) => (
      <Heading size="h5" className="text-center">
        {children}
      </Heading>
    ),
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.HEADING_6]: (_, children) => (
      <Heading size="h6" className="text-center">
        {children}
      </Heading>
    ),

    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Paragraph size="body4">{children}</Paragraph>
    ),
  },
  renderMark: {
    /**
     * Bold text
     *
     * @param {React.ReactNode} text - Text to render
     * @returns {React.ReactNode} - The formatted text
     */
    [MARKS.BOLD]: (text) => <span className="text-base-300">{text}</span>,
  },
};

/**
 * LogoGrid
 *
 * @param {ILogoGrid} props - The props for the LogoGrid component
 * @returns {React.FC<ILogoGrid>} Component
 */
const LogoGrid: React.FC<ILogoGrid> = ({ heading, logos }) => {
  // Get the current window size
  const size = useWindowSize();

  // Set the number of logical columns based on the window size
  const columns = size.breakpoint === 'xs' ? 3 : 5;

  return (
    <PageWrapper classes="page-max-w px-6 sm:px-8 md:px-10">
      <div data-testid="logo-grid">
        <div data-testid="logo-grid-heading" className="mb-8 md:mb-12">
          {documentToReactComponents(heading, options)}
        </div>

        <div className="grid grid-cols-[repeat(3,_70px)] sm:grid-cols-[repeat(5,_70px)] md:grid-cols-[repeat(5,_150px)] gap-y-6 sm:gap-y-8 md:gap-y-12 justify-between">
          {logos.map((logo, index) => (
            <AnimateItem
              key={logo.url}
              from={{ translateY: 20 }}
              delay={(index % columns) * 0.2 + 0.1}
            >
              <div className="w-[70px] md:w-[150px] relative aspect-[35/24]">
                <CdnImage
                  src={logo.url}
                  alt={logo.alt}
                  layout="fill"
                  objectFit="contain"
                  loader={contentFulImageLoader}
                  sizes="(max-width: 739px) 70px, (max-width: 1199px) 150px, 150px"
                />
              </div>
            </AnimateItem>
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default LogoGrid;
