import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import Carousel from '@/components/organisms/Carousel/Carousel';
import PageWrapper from '@/components/organisms/PageWrapper/PageWrapper';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { twMerge } from 'tailwind-merge';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';
import { assemblyFactory } from '@/lib/dataSource/contentful/assemblyFactory';

/** Props for the CarouselContainer component */
type CarouselContainerProps =
  /** The props for the Carousel */
  ContainerProps['carousel'] & {
    /** Classname for the container */
    className?: string;
    /** To hide the slider dots */
    hideDots?: boolean;
  };

/**
 * @param {CarouselContainerProps} props - CarouselContainerProps
 * @returns {React.ReactNode} - CarouselContainer component
 */
export const CarouselContainer = (props: CarouselContainerProps) => {
  const backgroundColor = props.fields.background;

  return (
    <div
      style={{
        backgroundColor,
      }}
    >
      <PageWrapper
        classes={twMerge('py-16 sm:py-20 md:py-28', props.className)}
      >
        <div className="flex items-end flex-row">
          <div className="flex-grow text-center">
            {props.fields.overline && (
              <AnimateItem from={{ translateY: 20 }}>
                <Paragraph
                  size="body4"
                  font="petco"
                  className="text-neutral-800 mb-2"
                >
                  {props.fields.overline}
                </Paragraph>
              </AnimateItem>
            )}
            {props.fields.headline && (
              <AnimateItem from={{ translateY: 20 }}>
                <Heading
                  size="h2"
                  font="amasis"
                  className="text-neutral-800 [&>*]:text-neutral-800 mb-12 sm:mb-16 md:mb-20"
                >
                  {documentToReactComponents(props.fields.headline, {
                    renderMark: {
                      /**
                       * Bold
                       *
                       * @param {string} text - The text to be bolded
                       * @returns {React.ReactElement} Component
                       */
                      bold: (text) => (
                        <strong className="text-mkPurple-400 font-petco">
                          {text}
                        </strong>
                      ),
                    },
                  })}
                </Heading>
              </AnimateItem>
            )}
            {props.fields.copy && (
              <AnimateItem from={{ translateY: 20 }} delay={0.4}>
                <div className="mb-8 sm:mb-9 md:mb-12">
                  <Paragraph size="body1" className="font-bold">
                    {props.fields.copy}
                  </Paragraph>
                </div>
              </AnimateItem>
            )}
          </div>
        </div>
        {props.fields?.elements?.length ? (
          <Carousel>
            {
              assemblyFactory.createBlockElements(
                props.fields.elements,
                false
              ) as React.ReactNode[]
            }
          </Carousel>
        ) : null}
      </PageWrapper>
    </div>
  );
};
