import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import AccordionContent, {
  IAccordionContentItem,
} from '@/components/molecules/AccordionContent/AccordionContent';

/**
 * IAccordionFaq Interface for Accordion FAQ component
 *
 * @interface
 */
export interface IAccordionFaq {
  /** The title of the FAQ Accordion. */
  title: string;
  /** The subtitle of the FAQ Accordion. */
  subTitle: string;
  /** The content array of the FAQ accordion. */
  content: IAccordionContentItem[];
  /**
   * The classes to apply to the FAQ Accordion.
   *
   * @default ''
   */
  classes?: string;
}

/**
 * Accordion FAQ The Accordion FAQ displays a title, subtitle and the accordion
 * content
 *
 * @param {IAccordionFaq} props - The props for the Accordion FAQ component
 * @returns {React.FC<IAccordionFaq>} Accordion FAQ Component
 */
const AccordionFaq: React.FC<IAccordionFaq> = ({
  title,
  subTitle,
  content,
  classes,
}) => {
  return (
    <div data-testid="accordion-faq" className={classes}>
      <AnimateItem from={{ translateY: 20 }}>
        <div className="sm:grid sm:grid-cols-6 md:block">
          <Heading
            size="h2"
            font="amasis"
            className="col-start-2 col-span-4 text-center mb-2 md:mb-4"
          >
            {title}
          </Heading>
          <Paragraph
            size="body3"
            className="col-start-2 col-span-4 text-center mb-6 md:mb-9"
          >
            {subTitle}
          </Paragraph>
        </div>
        <AccordionContent content={content} />
      </AnimateItem>
    </div>
  );
};

AccordionFaq.defaultProps = {
  title: 'Example FAQ Accordion',
  subTitle: 'Sub title',
  classes: '',
};

export default AccordionFaq;
