import { IButton } from '@/components/atoms/Button/Button';
import HeroHomepage from '@/components/organisms/HeroHomepage/HeroHomepage';
import { IHomePageTemplateContent } from '@/components/templates/HomePageTemplate/HomePageTemplate';
import { useEventContext } from '@/lib/hooks/analytics/useEventContext';
import { useUserContext } from '@/lib/hooks/userContext/UserContext';
import { startPetSearchEventHandler } from '@/lib/utils/analytics/startPetSearch';
import CookieStorage from '@/lib/utils/storage/cookie-storage';
import { default as staticContent } from '@/locales/en/pages/HomePageTemplate.json';
import { useUser } from '@auth0/nextjs-auth0/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ContainerProps } from '..';

/** Props for the HeroHomeContainer */
type HeroHomeContainerProps = ContainerProps['heroHome'] & {
  /** Class name */
  className?: string;
};

/**
 * The href for the I lost a Pet Button
 *
 * @constant {string}
 */
const iLostAPetHref = '/i-lost-a-pet';

/**
 * The href for the I found a Pet Button
 *
 * @constant {string}
 */
const iFoundAPetHref = '/i-found-a-pet';

/** The static text used in the page */
const content: IHomePageTemplateContent = staticContent.components;

/**
 * @param {HeroHomeContainerProps} props - HeroHomeContainer props
 * @returns {React.ReactNode} - HeroHomeContainer
 */
export const HeroHomeContainer = (props: HeroHomeContainerProps) => {
  /**
   * Allows us to lever the useUser hook from Auth0
   *
   * @constant {object} user - The user object from Auth0
   */
  const { user } = useUser();

  const { userType } = useUserContext();

  /**
   * Whether the user is logged in
   *
   * @constant {boolean} loggedIn - Whether the user is logged in
   */
  const loggedIn = user !== undefined;
  const isShelter = userType === 'shelter';

  const { setInitiatingComponent } = useEventContext();

  /** The router */
  const router = useRouter();

  /**
   * @constant {IButton[]} loggedOutButtons - The buttons to display when the
   *   user is logged out for the Hero Homepage Component.
   */
  const loggedOutButtons: IButton[] = [
    {
      children: content.hero.buttons.loggedOut.lost,
      size: 'lg',
      variant: 'light',
      color: 'primary',
      onClick:
        /**
         * Handle the click event for the I Lost a Pet Button
         *
         * @returns {void}
         */
        (): void => {
          const initiatingComponent = 'I Lost A Pet Button / Homepage';
          setInitiatingComponent(initiatingComponent);

          startPetSearchEventHandler({
            clickText: content.hero.buttons.loggedOut.lost,
            component: initiatingComponent,
            flow: 'Found',
          });
          router.push(iLostAPetHref);
        },
    },
    {
      children: content.hero.buttons.loggedOut.found,
      size: 'lg',
      variant: 'light',
      color: 'secondary',
      onClick:
        /**
         * Handle the click event for the I Found a Pet Button
         *
         * @returns {void}
         */
        (): void => {
          const initiatingComponent = 'I Found A Pet Button / Homepage';
          setInitiatingComponent(initiatingComponent);

          startPetSearchEventHandler({
            clickText: content.hero.buttons.loggedOut.found,
            component: initiatingComponent,
            flow: 'Lost',
          });
          router.push(iFoundAPetHref);
        },
    },
  ];

  /**
   * @constant {IButton[]} loggedInUserButtons - The buttons to display when the
   *   user is logged in
   */
  const loggedInUserButtons: IButton[] = [
    {
      children: content.hero.buttons.loggedIn.manage,
      size: 'lg',
      variant: 'light',
      color: 'primary',
      href: '/dash',
    },
  ];

  /**
   * @constant {IButton[]} loggedInShelterButtons - The buttons to display when
   *   the shelter is logged in
   */
  const loggedInShelterButtons: IButton[] = [
    {
      children: 'Dashboard Home',
      size: 'lg',
      variant: 'light',
      color: 'primary',
      href: '/shelter-dashboard/pets',
    },
    {
      children: content.hero.buttons.loggedOut.found,
      size: 'lg',
      variant: 'light',
      color: 'secondary',
      onClick:
        /**
         * Handle the click event for the I Found a Pet Button
         *
         * @returns {void}
         */
        (): void => {
          const initiatingComponent = 'I Found A Pet Button / Shelter Homepage';
          setInitiatingComponent(initiatingComponent);

          startPetSearchEventHandler({
            clickText: content.hero.buttons.loggedOut.found,
            component: initiatingComponent,
            flow: 'Lost',
          });
          router.push(iFoundAPetHref);
        },
    },
  ];

  /**
   * Determine what buttons to use on the homepage hero
   *
   * @returns {IButton[]} The buttons to display on the homepage hero
   */
  const heroButtons = (): IButton[] => {
    if (loggedIn) {
      if (isShelter) {
        return loggedInShelterButtons;
      }
      return loggedInUserButtons;
    }
    return loggedOutButtons;
  };

  useEffect(() => {
    const cookies = new CookieStorage();
    if (router.query.logout === 'true') {
      localStorage.clear();
      sessionStorage.clear();
      cookies.delete('userType');
    }
  });

  return (
    <HeroHomepage
      title={
        isShelter
          ? "We're here to help you reunite pets"
          : documentToReactComponents(props.fields.richHeadline)
      }
      text={
        isShelter
          ? 'Visit your dashboard to manage pets in your care or search for a pet you found in the field.'
          : props.fields.copy
      }
      buttons={heroButtons()}
      bgColor={props.fields.backgroundColor}
    />
  );
};
